import Layout from "./components/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import StaticContent from "./components/StaticContent";
import Home from "./components/Home";
import Products from "./components/Products";
import {
  useGetAboutUsQuery,
  useGetPagesQuery,
  useGetProductsQuery,
  useGetWelcomeTitlesQuery,
} from "./api/pages";

function App() {
  const { isLoading: isPagesLoading, data: pages = [] } = useGetPagesQuery();
  const { isLoading: isProductsLoading, data: products = [] } =
    useGetProductsQuery();
  const {
    isLoading: isWelcomeLoading,
    data: welcomeData = { Title1: "", Title2: "" },
  } = useGetWelcomeTitlesQuery();
  const { isLoading: isAboutUsLoading, data: aboutData = "" } =
    useGetAboutUsQuery();

  if (
    isPagesLoading ||
    isProductsLoading ||
    isWelcomeLoading ||
    isAboutUsLoading
  )
    return (
      <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
        <div role="status" className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout pages={pages} />}>
          <Route
            index
            element={
              <Home
                products={products}
                aboutData={aboutData}
                welcomeData={welcomeData}
              />
            }
          />
          {pages.map(({ _id, Title, Link }) => (
            <Route
              key={_id}
              path={Link}
              element={
                Link === "products-and-pricing" ? (
                  <Products products={products} />
                ) : (
                  <StaticContent pages={pages} />
                )
              }
            />
          ))}
        </Route>
        <Route
          path="*"
          element={
            <Layout pages={pages}>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
