import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BASE_URL } from "../api/consts";
import { html2String, Product, WelcomeData } from "../api/pages";
import Welcome from "./Welcome";

interface Props {
  products: Product[];
  welcomeData: WelcomeData;
  aboutData: string;
}

const Home = ({ products, welcomeData, aboutData }: Props) => {
  return (
    <>
      <Helmet>
        <title>Home - Vinresol</title>
      </Helmet>
      {welcomeData && <Welcome titles={welcomeData} />}
      <div className="bg-white pt-5 pb-5 about-content section-title">
        {aboutData && (
          <div
            dangerouslySetInnerHTML={{ __html: aboutData }}
            className="container"
          />
        )}
      </div>
      <div className="container pt-5">
        <h2 className="">Our focus</h2>
        <div className="row pt-5 pb-5 service-section justify-content-center">
          {products?.map((product, index) => (
            <div className="col" key={index}>
              <p
                className="mb-1"
                dangerouslySetInnerHTML={{ __html: product.Name }}
              />
              <Link
                to={{
                  pathname: "/products-and-pricing",
                  hash: html2String(product.Name),
                }}
                className="d-block"
              >
                <div
                  className="single-service position-relative"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    alt={product.Name}
                    src={BASE_URL + "/" + product.Image2}
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
