import { html2String, Product } from "../api/pages";
import { BASE_URL } from "../api/consts";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const formatMoney = (number = 0) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

interface Props {
  products: Product[];
}

const CellTitle = ({ children }: { children: string | JSX.Element }) => (
  <span className="d-md-none d-inline-block">{children}</span>
);

const Products = ({ products }: Props) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "") return;
    const element = document.querySelector(hash);
    element?.scrollIntoView({ block: "center" });
  }, [hash]);

  return (
    <div className="container">
      <Helmet>
        <title>Products and Pricing - Vinresol</title>
      </Helmet>
      <h1 className="mb-5">Products and Pricing</h1>
      {products.map(({ Name, Description, Image, Prices }, index) => (
        <section id={html2String(Name)} key={index} className="col-12">
          <article className="product-card">
            <div className="row">
              <div className="col-md-5">
                <h3
                  className="mb-3"
                  dangerouslySetInnerHTML={{ __html: Name }}
                />
                {Image && (
                  <img
                    alt={Name}
                    width="300"
                    height="145"
                    src={`${BASE_URL}/${Image}`}
                  />
                )}
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{ __html: Description }}
                />
              </div>
              <div className="col-md-7">
                <div className="price-table">
                  <div className="row g-0">
                    <div
                      className="col-md-4 p-2"
                      dangerouslySetInnerHTML={{ __html: Name }}
                    />
                    <div className="col-md-8 d-none d-md-block p-2 text-center">
                      UNITS
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-md-4">
                      <div className="row g-0">
                        <div className="col-md-7 price-col first">
                          <div className="fw-bold">Volume</div>
                          <div>Concentration</div>
                          <div>Content</div>
                          <div className="fw-bold">Unit prices</div>
                          <div className="fw-bold">Unit prices</div>
                        </div>
                        <div className="col-md-5 price-col second">
                          <div className="fw-bold">mL</div>
                          <div>mg/mL</div>
                          <div>mg</div>
                          <div className="fw-bold">[EUR]</div>
                          <div className="fw-bold">[USD]</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 prices">
                      {Prices.map((price) => (
                        <div className="price-col" key={price.USD}>
                          <div className="fw-bold">
                            <CellTitle>Volume (mL):</CellTitle>
                            {Number(price.Volume).toFixed(1)}
                          </div>
                          <div>
                            <CellTitle>Concentration (mg/mL):</CellTitle>
                            {Number(price.Concentration).toFixed(2)}
                          </div>
                          <div>
                            <CellTitle>Content (mg):</CellTitle>
                            {(price.Concentration * price.Volume).toFixed(1)}
                          </div>
                          <div className="fw-bold">
                            <CellTitle>Price (EUR):</CellTitle>
                            {formatMoney(price.EUR)}
                          </div>
                          <div className="fw-bold">
                            <CellTitle>Price (USD):</CellTitle>
                            {formatMoney(price.USD)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      ))}
    </div>
  );
};

export default Products;
