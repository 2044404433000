import { API_TOKEN, API_URL } from "./consts";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Page {
  _id: number;
  Title: string;
  Link: string;
  Content?: string;
}

export interface WelcomeData {
  Title1: string;
  Title2: string;
}

export interface Price {
  Volume: number;
  Concentration: number;
  EUR: number;
  USD: number;
}

export interface Product {
  Name: string;
  Description: string;
  Image?: string;
  Image2?: string;
  Prices: Price[];
}

export const html2String = (html: string): string =>
  html
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export const strapiApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      API_TOKEN && headers.set("Cockpit-Token", API_TOKEN);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPages: builder.query<Page[], void>({
      query: () => ({
        method: "POST",
        url: `/collections/get/Page`,
        body: {
          sort: { Order: 1 },
        },
      }),
      transformResponse: (response: { entries: Page[] }) =>
        response.entries.map((entry) => ({
          ...entry,
          Link:
            entry.Link ??
            encodeURI(
              entry.Title.toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
            ),
        })),
    }),
    getWelcomeTitles: builder.query<WelcomeData, void>({
      query: () => ({
        url: `/singletons/get/Welcome`,
      }),
    }),
    getProducts: builder.query<Product[], void>({
      query: () => ({
        url: `/collections/get/Product`,
      }),
      transformResponse: (response: any) =>
        response?.entries.reduce((products: Product[], currentProduct: any) => {
          products.push({
            Name: currentProduct.Name,
            Description: currentProduct.Description,
            Image: currentProduct.Image?.path,
            Image2: currentProduct.Image2?.path,
            Prices: currentProduct.Prices?.map(
              (price: { value: Price }) => price.value
            ),
          });
          return products;
        }, [] as Product[]),
    }),
    getAboutUs: builder.query<string, void>({
      query: () => ({
        url: `/singletons/get/AboutUs`,
      }),
      transformResponse: (response: { Content?: string }) =>
        response?.Content || "",
    }),
  }),
});

export const {
  useGetPagesQuery,
  useGetAboutUsQuery,
  useGetWelcomeTitlesQuery,
  useGetProductsQuery,
} = strapiApi;
